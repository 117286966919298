<template style="margin: 0px ; padding: 0px">
    <el-container>
        <el-header style="height: 120px;position: relative;width: 100%;">
            <div style="display: flex;flex-direction:row;vertical-align: center;justify-content:center">
                <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" style="align-items: center" :default-active="search_data.categories_id">
                    <el-menu-item index="all" @click="changeCate('all')" >全部</el-menu-item>
                    <el-menu-item index="new" @click="changeCate('new')">新品</el-menu-item>
                    <el-menu-item :index="item.id" v-for="(item,index) in categories_list" :key="index" @click="changeCate(item.id)">{{item.name}}</el-menu-item>
                </el-menu>
            </div>
            <div style="display: flex;flex-direction:row;vertical-align: center;justify-content:center;margin-top: 10px">
                <el-input
                        placeholder="输入图片名称"
                        v-model="search_data.name" style="width: 30%;margin-right: 10px"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="getMaterialList()">搜索</el-button>
            </div>
        </el-header>
        <el-main class="main_css" style=" margin-top: 12px;padding-top: 0px">
            <el-row :gutter="10" style="margin: 0px; padding: 0px">
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-for="(item, index) in img_data_list_onload" :key="index">
                    <div class="grid-content" v-for="(item1,index1) in item" :key="index1" @mouseenter="enters(index,index1)" @mouseleave="leaver()">
                        <el-image :src="item1.img" lazy fit="fill" :preview-src-list="yulan_list" @contextmenu.prevent="show1()" style="position: initial;"></el-image>
                        <div v-if="c_item == index+','+index1" style="height: 44px;width: 100%;margin-top: -18%;"><el-button type="success" @click="upload(item1.img,item.name)">下载</el-button></div>
                        <el-tabs style="text-align: start;">{{item1.name}}</el-tabs>
                    </div>
                </el-col>
            </el-row>
        </el-main>
    </el-container>


</template>

<script>
    import {getCategroies , getMaterialList} from '../../api/domains'
    export default {
        data(){
            return{
                img_data_list_onload:[],
                img_data_list:[],
                col:6,
                c_index:0,
                length_list:[],
                yulan_list:[],
                c_item:'',
                search_data:{categories_id:'new',name:'',page:1,page_size:20},
                categories_list:[]
            }
        },
        computed:{

        },
        created() {
            //初始就加载数据
            this.getCategroies()
            this.getMaterialList()
        },
        methods:{
             setLengthList() {
                 this.img_data_list_onload = []
                 for (var i = 0 ; i < this.col ; i++){
                     this.length_list[i] = 0;
                     this.img_data_list[i] = []
                 }
             },
            getMaterialList() {
                var _this = this
                _this.setLengthList();
                getMaterialList(this.search_data).then(function (res) {
                    if(res.data.code == 200) {
                        let lg = res.data.data.length;
                        console.log(lg)
                        for (let i = 0 ; i < lg ; i++) {
                            let img = new Image()
                            let url = res.data.data[i]['img']
                            console.log(res.data.data[i])
                            img.src = url
                            let name = res.data.data[i]['name']
                            img.onload = function () {
                                _this.render({
                                    index: i,
                                    url: url,
                                    ratio: img.width / img.height,
                                    height: img.height,
                                    total: lg,
                                    name:name
                                })
                            }
                        }
                    }else {
                        _this.$alert(res.data.message, '提示', {
                            confirmButtonText: '确定',
                            callback: function () {

                            }
                        })
                    }
                })
            },
            getCategroies(){
                const _this = this
                getCategroies({}).then(function (res) {
                    console.log(res)
                    if (res.data.code === "200") {
                        _this.categories_list = res.data.data
                    }else {
                        _this.$alert(res.data.message, '提示', {
                            confirmButtonText: '确定',
                            callback: function () {

                            }
                        })
                    }
                })
            },
            show1(){
                this.$alert('静止右键', '提醒');
            },
            changeCate(value){
                this.search_data.categories_id = value
                this.getMaterialList()
            },
            upload(url,name) {
                var image = new Image();

                // 解决跨域 Canvas 污染问题

                image.setAttribute("crossOrigin", "anonymous");
                image.onload = function() {
                    var canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, image.width, image.height);
                    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                    var a = document.createElement("a"); // 生成一个a元素
                    var event = new MouseEvent("click"); // 创建一个单击事件
                    a.download = name || "photo"; // 设置图片名称
                    a.href = url; // 将生成的URL设置为a.href属性
                    a.dispatchEvent(event); // 触发a的单击事件
                };
                image.src = url;
            },
            enters(index,index1) {
                this.c_item = index+','+index1;
            },
            leaver(){
                this.c_item = ''
            },
            render(imgInfo) {
                let minHeight = Math.min.apply(null, this.length_list)
                let minIndex = this.length_list.indexOf(minHeight)
                this.length_list[minIndex] += imgInfo.height
                var img_data = {}
                img_data.img = imgInfo.url
                img_data.name = imgInfo.name
                this.img_data_list[minIndex].push(img_data)
                var length = imgInfo.total - 1
                this.yulan_list.push(imgInfo.url)
                if(imgInfo.index == length){
                    console.log(this.img_data_list)
                    this.img_data_list_onload = this.img_data_list
                }
            }

        }
    }
</script>
<style>
    .grid-content {
        min-height: 36px;
        margin-top: 10px;
    }
    .main_css{
        padding-top: 0px;
        position: absolute;
        left: 0px;
        right: 0;
        top: 120px;
        bottom: 0;
        overflow-y: auto;
    }

    .main_css::-webkit-scrollbar {
        width: 0px;
    }
</style>
