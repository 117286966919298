import Vue from "vue";
import VueRouter from "vue-router";

import PcIndex from "../views/pc/index";
import MobileIndex from "../views/mobile/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/p_index",
    name: "pIndex",
    component: PcIndex
  },
  {
    path: "/m_index",
    name: "mIndex",
    component: MobileIndex
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
